<template>
    <SectionsNav>
        <template #introduction>
            <a class="nav-link" @click="showSection('presentation')">Обзор</a>
        </template>
        <template #programs>
            <a class="nav-link" @click="showSection('programs')">Программы</a>
        </template>
        <template #campuses>
            <a class="nav-link" @click="showSection('campus')">Кампусы</a>
        </template>
        <template #selectSection>
            <select id="select-mobile-menu" class="form-select form-select-lg" v-model="this.currentSection">
                <option value="presentation">Обзор</option>
                <option value="programs">Программы</option>
                <option value="campus">Кампусы</option>
            </select>
        </template>
    </SectionsNav>
    
    <Obzor v-if="currentSection === 'presentation'" ref="presentation" class="presentation">
        <template #title>
            Обзор
        </template>
        <template #description> 
            Языковая школа с кампусами в Париже, Бордо, Ницце, Лионе и Биаррице. Учебное заведение предлагает краткосрочные и долгосрочные курсы французского языка для взрослых и подростков. Парижский кампус находится в самом центре города, в 5-м округе, рядом со знаменитым Нотр-Дамом. В Биаррице каждое лето проходит языковой лагерь для подростков: изучение языка + серфинг. Формат обучения для взрослых на долгосрочных курсах предполагает обучение на кампусе + онлайн уроки. Кроме этого, есть программы подготовки к сдаче экзамена DELF/DALF. 
        </template>
        <template #speciality>
            языковая школа
        </template>
    </Obzor>


    <section v-if="currentSection === 'programs'" ref="programs" class="programs">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 title-main">
                            <h1>Программы</h1>
                        </div>
                    </div>
                    
                    <div class="row program-select-wrapper">
                        <div class="col-12 col-lg-4">
                            <select v-model="program" ref="programSelector" class="form-select form-select-lg">
                                <option disabled value="">Выбрать программу</option>
                                <option value="kk">Краткосрочные курсы</option>
                                <option value="dk">Долгосрочные курсы</option>
                                <option value="ppu">Программа подготовки к учебе</option>
                                <option value="ufs">Учеба во французской средней школе</option>
                                <option value="ufsf">Учеба во французской - Полный год</option>
                                <option value="ll">Летний лагерь</option>
                            </select>
                        </div>
                    </div>
                    
                    <Program :url="''" v-if="this.program === 'kk'" id="kk">
                        <template #title>
                            Краткосрочные курсы
                        </template>
                        <template #description>
                            Краткосрочные курсы французского языка для взрослых (20 уроков в неделю)
                        </template>
                        <template #duree>
                            20 часов в неделю
                        </template>
                        <template #cost>
                            от 254 евро/неделя
                        </template>
                        <template #campus>
                            Париж
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'dk'" id="dk">
                        <template #title>
                            Долгосрочные курсы
                        </template>
                        <template #description>
                            Долгосрочные курсы французского языка для взрослых
                        </template>
                        <template #duree>
                            12 недель
                        </template>
                        <template #cost>
                           1885 евро
                        </template>
                        <template #campus>
                            Париж
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'ppu'" id="ppu">
                        <template #title>
                            Программа подготовки к учебе
                        </template>
                        <template #description>
                            Программа подготовки к учебе в университете/сдаче экзамена DELF/DALF
                        </template>
                        <template #duree>
                            8 месяцев
                        </template>
                        <template #cost>
                           5120 евро
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Январь - Август
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'ufs'" id="ufs">
                        <template #title>
                            Учеба во французской средней школе
                        </template>
                        <template #description>
                            Учеба во французской средней школе (11-15 лет)
                        </template>
                        <template #duree>
                             Один семестр
                        </template>
                        <template #cost>
                           5895 евро
                        </template>
                        <template #campus>
                            Париж
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'ufsf'" id="ufsf">
                        <template #title>
                            Учеба во французской средней школе
                        </template>
                        <template #description>
                            Учеба во французской средней школе (11-15 лет)
                        </template>
                        <template #duree>
                            Полный год
                        </template>
                        <template #cost>
                           14.335 евро
                        </template>
                        <template #campus>
                            Париж
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'll'" id="ll">
                        <template #title>
                            Летний лагерь
                        </template>
                        <template #description>
                            Летний лагерь для детей и подростков в Биаррице
                        </template>
                        <template #duree>
                            от 1 неделя
                        </template>
                        <template #cost>
                           от 1060 евро/неделя
                        </template>
                        <template #campus>
                            Биарриц
                        </template>
                    </Program>

                </div>
            </div>
        </div>
    </section>

    <CampusMain v-if="currentSection === 'campus'" ref="campus" class="campus">
    </CampusMain>

</template>
<script>
import Program from '@/components/schools/slots/Program.vue'
import CampusMain from '@/components/schools/slots/CampusMain.vue'
import SectionsNav from '@/components/schools/slots/SectionsNav.vue'
import Obzor from '@/components/schools/slots/Obzor.vue'
import Campus from '@/components/schools/slots/Campus.vue'

export default {
    data(){
        return {
            currentSection:"presentation",
            program : ""
        }
    },
    components:{
        Program,
        CampusMain,
        SectionsNav,
        Obzor,
        Campus
    },
    methods:{
        showSection(section){
            this.currentSection = section;
            this.desktopScroll();
        },
        desktopScroll(){
            let scrollToElement = document.querySelector(".menu")
            scrollToElement.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }
}
</script>