<template>
    <Introduction :url="'/brochure/france-langue'" :class="'introduction-france-langue'">  
        <template #title>
            France Langue
        </template>
        <template #speciality>
            языковая школа
        </template>
        <template #min-duration>
            От 20 часов в неделю
        </template>
        <template #cost>
            От 254 евро/неделя
        </template>
        <template #campus>
            Париж, Бордо, Лион, Ницца, Биарриц
        </template>
    </Introduction>  
</template>
<script>
import Introduction from '@/components/schools/slots/Introduction.vue'
export default {
    components:{
        Introduction
    }
}
</script>